import React, { useEffect, useState } from "react";

//jQuery initialization
import $ from "jquery";
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    managePageHrefLink,
    managePageHrefLinkMobile,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";


const ReservationSidebar = () => {

    const { t } = useTranslation();
    const [templateValue, setTemplateValue] = useState(null);

    useEffect(() => {
        getActivatedTemplate();
        handleJquery();
    }, []);

    // getTemplate value
    const getActivatedTemplate = (e) => {
        const url = BASE_URL + "/settings/get-template-value";
        return axios
            .get(url)
            .then((res) => {
                setTemplateValue(res.data);
                console.log('template sidebar', res);
            });
    }

    //jQuery goes here
    const handleJquery = () => {
        var posHasSub = $(".fk-pos-nav__list-has-sub");
        var posSub = $(".fk-pos-nav__sub");
        $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
            e.preventDefault();
        });
        posHasSub.on("click", function () {
            $(this).find(posSub).slideDown();
            $(this).siblings().find(posSub).slideUp();
            $(this).addClass("active").siblings().removeClass("active");
        });
    };

    return (
        <>
            {/* Navigation for Small Screen  */}
            <div className="d-lg-none">
                <div className="row">
                    <div className="col-12">
                        <div className="fk-sm-nav" data-simplebar>

                            <ul className="t-list fk-sm-nav__bar flex-row">
                                {/* Template two settings */}
                                {managePageHrefLinkMobile(
                                    "/dashboard/reservation/all-reserve-list",
                                    _t(t("Reservation list"))
                                )}
                                {managePageHrefLinkMobile(
                                    "/dashboard/reservation/add-bookiing",
                                    _t(t("Add booking"))
                                )}
                                {/* {managePageHrefLinkMobile(
                                    "/dashboard/reservation/set-reservation",
                                    _t(t("Set reservation"))
                                )}
                                {managePageHrefLinkMobile(
                                    "/dashboard/reservation/unavailable-book",
                                    _t(t("Set unavailable"))
                                )} */}
                                {managePageHrefLinkMobile(
                                    "/dashboard/reservation/add-table",
                                    _t(t("add table types"))
                                )}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            {/* Navigation for Small Screen  End*/}

            {/* Navigation for large Screen  */}
            <div className="d-none d-lg-block">
                <div className="fk-scroll--pos-menu" data-simplebar>
                    <ul className="t-list fk-pos-nav">
                        {/* Foods */}
                        <li
                            className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname.includes("/reservation/") ? "active" : ""
                                }`}
                        >
                            <a
                                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                                rel="noopener noreferrer"
                                href="#"
                            >
                                {
                                    _t(t("Reservation"))
                                }

                            </a>

                            <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                                {managePageHrefLink(
                                    "/dashboard/reservation/all-reserve-list",
                                    _t(t("Reservation list"))
                                )}
                                {managePageHrefLink(
                                    "/dashboard/reservation/add-bookiing",
                                    _t(t("Add booking"))
                                )}
                                {/* {managePageHrefLink(
                                    "/dashboard/reservation/set-reservation",
                                    _t(t("Set reservation"))
                                )}
                                {managePageHrefLink(
                                    "/dashboard/reservation/unavailable-book",
                                    _t(t("Set unavailable"))
                                )} */}
                                {managePageHrefLink(
                                    "/dashboard/reservation/add-table",
                                    _t(t("add table types"))
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Navigation for large Screen End */}
        </>
    );
};

export default ReservationSidebar;

