import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import ReservationSidebar from "./ReservationSidebar";

const AddTable = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);

    // state starts here
    const [table, setTable] = useState({
        type: null,
        max_people: null,
    });

    // status date
    const [status, setStatus] = useState("open");

    // onchange function
    const handleOnChange = (e) => {
        // e.persist();
        setTable({
            ...table,
            [e.target.name]: e.target.value
        })
    }

    // use effect
    useEffect(() => {
        // getAllOpenTables();
    }, []);

    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();

        const data = {
            type: table.type,
            max_people: table.max_people,
        };
        // console.log('eita hoise cyBarPunk ', data);

        const url = BASE_URL + `/settings/add-table-type`;
        axios.post(url, data, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            console.log(res);
            // if (res.data.status == 200) {
            //     toast.success(`${_t(t("up successfully"))}`, {
            //         position: "bottom-center",
            //         autoClose: 10000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         className: "text-center toast-notification",
            //     });
            // } else if (res.data.status == 422) {
            //     toast.error(`${_t(t("Try again"))}`, {
            //         position: "bottom-center",
            //         autoClose: 10000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         className: "text-center toast-notification",
            //     });
            // } else {
            //     toast.error(`${_t(t("Something went Wrong!!"))}`, {
            //         position: "bottom-center",
            //         autoClose: 10000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         className: "text-center toast-notification",
            //     });
            // }
        });
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("AddTable"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReservationSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">
                                        <form onSubmit={handlesubmit} className="row card p-2 mx-3 mb-5 sm-text">
                                            <div className="col-12">
                                                <div className="form-group mt-3 add_booking_form">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Add table type<span className="text-danger">*</span></label>
                                                    </div>

                                                    <div className="mb-2">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="name" placeholder="Name" required="" defaultValue={table.name} />
                                                    </div>

                                                    <div className="mb-2">
                                                        <label>Total Number</label>
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="tableType" placeholder="tableType" required="" defaultValue={table.tableType} />
                                                    </div>

                                                </div>

                                                <div className="form-group mt-5 pb-2">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-primary px-5" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default AddTable;
