import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import ReservationSidebar from "./ReservationSidebar";


const Addbooking = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);

    // state starts here
    const [booking, setBooking] = useState({
        tableNo: null,
        total_guest: null,
        name: null,
        phone: null,
        email: null,
        note: null,
    });

    // status date
    const [status, setStatus] = useState("open");
    // date
    const [date, setDate] = useState(new Date().toLocaleDateString('fr-FR'));
    // startTime
    const [startTime, setStartTime] = useState({ time: "10:00" });
    // endTime
    const [endTime, setEndTime] = useState({ time: "12:00" });
    // all tables
    const [allTables, setAllTables] = useState([]);
    // set table id
    const [tableId, setTableId] = useState([]);
    // table type and maxNumber
    const [tableTypesInfo, setTableTypesInfo] = useState([]);



    // onchange function
    const handleOnChange = (e) => {
        // e.persist();
        setBooking({
            ...booking,
            [e.target.name]: e.target.value
        })
    }

    // status option
    const statusOptions = [
        { value: 'booked', label: 'Booked' },
        { value: 'open', label: 'Open' },
    ];

    // handle status
    const handleStatus = value => {
        setStatus(value.value);
    };

    // handle status
    const handleTables = value => {
        // setStatus(value);
        setTableId(value.id);
        setTableTypesInfo(value.restaurant_tables);
        console.log('this is state value from restaurant ', tableTypesInfo);
    };


    // getAllTables 
    const getAllOpenTables = () => {
        const url = BASE_URL + "/settings/get-table-options";
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            setAllTables(res.data);
            console.log('nigga tablessss', res);
        })
    }



    // use effect
    useEffect(() => {
        getAllOpenTables();
    }, []);

    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();

        const data = {
            name: booking.name,
            table_id: tableId,
            email: booking.email,
            phone: booking.phone,
            note: booking.note,
            total_guest: booking.total_guest,
            date: date,
            startTime: startTime.time,
            endTime: endTime.time,
            status: status,
        };
        console.log('eita hoise cyBarPunk ', data);


        const url = BASE_URL + `/settings/client-book-table/${tableId}`;
        axios.post(url, data, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.status == 200) {
                toast.success(`${_t(t("up successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            } else if (res.data.status == 422) {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            } else {
                toast.error(`${_t(t("Something went Wrong!!"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Addbooking"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReservationSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">
                                        <form onSubmit={handlesubmit} className="row card p-2 mx-3 mb-5 sm-text">
                                            <div className="col-12">
                                                <div className="form-group mt-3 add_booking_form">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Booking table<span className="text-danger">*</span></label>
                                                    </div>

                                                    <div className="mb-2">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="name" placeholder="Name" required="" defaultValue={booking.name} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Email</label>
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="email" placeholder="email@mail.com" required="" defaultValue={booking.email} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Phone number</label>
                                                        <input
                                                            type="number"
                                                            onChange={handleOnChange} className="form-control" name="phone" placeholder="Phone" required="" defaultValue={booking.phone} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Note</label>
                                                        <textarea

                                                            onChange={handleOnChange} className="form-control" name="note" placeholder="Write notes..." required="" defaultValue={booking.note}>
                                                        </textarea>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Select table</label>
                                                        {/* <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="tableNo" placeholder="01" required="" defaultValue={booking.tableNo} /> */}
                                                        <Select
                                                            options={allTables}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.name}
                                                            onChange={handleTables}
                                                        // defaultValue={{ value: 'open', label: 'Open' }}
                                                        />
                                                    </div>
                                                    {/* table types info */}
                                                    {
                                                        tableTypesInfo.type != null ? (
                                                            <div className="mb-2 d-flex align-items-center">
                                                                <div>
                                                                    <span className="d-inline-block font-weight-bold text-capitalize text-dark">
                                                                        Table Type :
                                                                    </span>
                                                                    <span className="d-inline-block  text-capitalize ml-2">
                                                                        {tableTypesInfo.type}
                                                                    </span>
                                                                </div>

                                                                <div className="ml-4">
                                                                    <span className="d-inline-block font-weight-bold text-capitalize text-dark">
                                                                        Max People :
                                                                    </span>
                                                                    <span className="d-inline-block  text-capitalize ml-2">
                                                                        {tableTypesInfo.max_people}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    }

                                                    <div className="mb-2">
                                                        <label>Total people</label>
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="total_guest" placeholder="5" required="" defaultValue={booking.total_guest} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Select Date</label>
                                                        <DatePicker
                                                            dateFormat="yyyy/MM/dd"
                                                            value={date}
                                                            onChange={(date) => {
                                                                const d = new Date(date).toLocaleDateString('fr-FR');
                                                                setDate(d)
                                                            }}
                                                            className="form-control"
                                                            // minDate={today}
                                                            todayButton={"Today"} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Select starting time</label>
                                                        <TimePicker
                                                            onChange={(time) => {
                                                                setStartTime({ time });
                                                            }}
                                                            value={startTime.time}
                                                            locale="sv-sv"
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Select ending time</label>
                                                        <TimePicker
                                                            onChange={(time) => {
                                                                setEndTime({ time });
                                                            }}
                                                            value={endTime.time}
                                                            locale="sv-sv"
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Select status</label>
                                                        <Select
                                                            options={statusOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value}
                                                            onChange={handleStatus}
                                                            defaultValue={{ value: 'open', label: 'Open' }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group mt-5 pb-2">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-primary px-5" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Addbooking;
