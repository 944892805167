import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
    _t,
    getCookie,
    modalLoading,
    tableLoading,
    pagination,
    paginationLoading,
    showingData,
    searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, SAAS_APPLICATION, saas_apiUrl, saas_apiParams, saas_form_data } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const PaypalSetup = () => {

    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let {
        //branch
        branchList,
        setBranchList,
        setPaginatedBranch,
        branchForSearch,
        setBranchforSearch,

        //pagination
        dataPaginating,
    } = useContext(RestaurantContext);


    //useEffect == componentDidMount
    useEffect(() => {
    }, []);



    return (
        <>
            <Helmet>
                <title>{_t(t("Paypal"))}</title>
            </Helmet>


            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white p-3 p-md-5">
                                <div>
                                    <h4 className="text-secodary my-4">Setup PayPal</h4>
                                </div>
                                <hr />
                                <form>
                                    <div class="mb-3">
                                        <label for="stripeclientid" class="form-label">PayPal Client Id</label>
                                        <input type="text" class="form-control" id="stripeclientid" placeholder="pk_test_51H2XGOKJQvOIJEOikQ62q1vuUW" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="stripesecretkey" class="form-label">PayPal Secret Key</label>
                                        <input type="text" class="form-control" id="stripesecretkey" placeholder="sk_test_51H2XGOKJQvOIJEOiu" />
                                    </div>
                                    <div class="mb-3">
                                        <button className="btn btn-primary text-capitalize">setup</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default PaypalSetup;
