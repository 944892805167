import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";
//pages & includes
import Templatesidebar from "./Templatesidebar";


const About = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);
    //states start here
    const [aboutContents, setAboutContent] = useState({
        // aboutUrl: getSystemSettings(templateOneSettings, "aboutUrl"),
        aboutTitle: getSystemSettings(templateOneSettings, "aboutTitle"),
        aboutDes: getSystemSettings(templateOneSettings, "aboutContent"),
        totalItems: getSystemSettings(templateOneSettings, "totalItems"),
    });

    const [smImage, setsmImage] = useState([]);
    const [mdImage, setmdImage] = useState([]);
    const [lgImage, setlgImage] = useState([]);

    // onchange function
    const handleOnChange = (e) => {
        e.persist();
        setAboutContent({
            ...aboutContents,
            [e.target.name]: e.target.value
        })
    }

    // handle image
    const handlesmImage = (e) => {
        setsmImage({ image: e.target.files[0] });
    };
    const handlemdImage = (e) => {
        setmdImage({ image: e.target.files[0] });
    };
    const handlelgImage = (e) => {
        setlgImage({ image: e.target.files[0] });
    };

    // use effect
    useEffect(() => {
        setAboutContent({
            aboutTitle: getSystemSettings(templateOneSettings, "aboutTitle"),
            aboutDes: getSystemSettings(templateOneSettings, "aboutContent"),
            totalItems: getSystemSettings(templateOneSettings, "totalItems"),
        })
    }, []);
    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("aboutsmImg", smImage.image);
        formData.append("aboutmdImg", mdImage.image);
        formData.append("aboutlgImg", lgImage.image);

        // formData.append("aboutUrl", aboutContents.aboutUrl);
        formData.append("totalItems", aboutContents.totalItems);
        formData.append("aboutTitle", aboutContents.aboutTitle);
        formData.append("aboutContent", aboutContents.aboutDes);

        const url = BASE_URL + "/settings/store-about-section";
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.status == 200) {
                toast.success(`${_t(t("updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            } else if (res.data.status == 422) {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            }
        });
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("About "))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <Templatesidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">

                                        <form onSubmit={handlesubmit} className="row card p-2 mx-3 mb-5 sm-text">
                                            <div className="col-12">
                                                <div className="form-group mt-4">
                                                    <div className="mb-2">
                                                        <label for="image" className="control-label">Small image<span className="text-danger">*</span> <small className="text-secondary">(130 x 120 Preferable)</small></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="file" className="form-control sm-text" id="image" name="smImage" onChange={handlesmImage} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-4">
                                                    <div className="mb-2">
                                                        <label for="image" className="control-label">Medium image<span className="text-danger">*</span> <small className="text-secondary">(200 x 210 Preferable)</small></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="file" className="form-control sm-text" id="image" name="smImage" onChange={handlemdImage} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-4">
                                                    <div className="mb-2">
                                                        <label for="image" className="control-label">Large image<span className="text-danger">*</span> <small className="text-secondary">(280 x 230 Preferable)</small></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="file" className="form-control sm-text" id="image" name="smImage" onChange={handlelgImage} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Total Food Items<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="text" className="form-control" name="totalItems" placeholder="ENJOY OUR DELICIOUS FOOD" required=""
                                                            defaultValue={aboutContents.totalItems}
                                                            onChange={handleOnChange} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">About title<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="text" className="form-control" name="aboutTitle" placeholder="ENJOY OUR DELICIOUS FOOD" required="" defaultValue={aboutContents.aboutTitle} onChange={handleOnChange} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">About Content<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <textarea className="form-control" name="aboutDes" placeholder="Get  your food" required="" defaultValue={aboutContents.aboutDes} onChange={handleOnChange} ></textarea>
                                                    </div>
                                                </div>

                                                <div className="form-group mt-5 pb-2">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-primary px-5" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default About;
