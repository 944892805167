import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import ReservationSidebar from "./ReservationSidebar";

const Allreservation = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);

    const productsGenerator = quantity => {
        const items = [];
        for (let i = 0; i < quantity; i++) {
            items.push({ id: i, name: `Item name ${i}`, price: 2100 + i });
        }
        return items;
    };

    //  hooks here
    const [reservation, setReservation] = useState([]);


    const columns = [
        {
            dataField: "id",
            text: "id",
            sort: true
        },
        {
            dataField: "name",
            text: "Name",
            sort: true
        },
        // {
        //     dataField: "user_id",//user_id
        //     text: "user name"
        // },
        {
            dataField: "table_id",//table_id
            text: "table name"
        },
        {
            dataField: "email",
            text: "Email"
        },
        {
            dataField: "phone",
            text: "Phone"
        },
        {
            dataField: "note",
            text: "Note"
        },
        {
            dataField: "total_guest",
            text: "Total Guest"
        },
        {
            dataField: "date",
            text: "Date"
        },
        {
            dataField: "startTime",
            text: "Start Time"
        },
        {
            dataField: "endTime",
            text: "End Time"
        },
        {
            dataField: "Edit",
            text: "Edit",
            formatter: (cellContent, row) => {
                return (
                    <button
                        className="btn btn-info btn-xs"
                        onClick={() => handleEdit(row.id, row.email, row.table_id, row.name)}
                    >
                        Edit
                    </button>
                );
            },
        },
        {
            dataField: "remove",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (
                    <button
                        className="btn btn-danger btn-xs"
                        onClick={() => handleDelete(row.id, row.email, row.table_id, row.name)}
                    >
                        Delete
                    </button>
                );
            },
        },
    ];

    // handleEdit
    const handleEdit = (rowId, email, table_id, name,) => {
        console.log(rowId, email, table_id, name,);
        //1 YourCellName
    };
    // handleDelete
    const handleDelete = (rowId, email, table_id, name,) => {
        console.log(rowId, email, table_id, name,);
        //1 YourCellName
    };


    // use effect
    useEffect(() => {
        getAllReserveData();
    }, []);

    const getAllReserveData = () => {
        const url = BASE_URL + "/settings/get-all-reserved-table";
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            console.log(res);
            setReservation(res.data);
        });
    }

    // handle submit
    // const handlesubmit = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     // formData.append("heroImage", picture.image);

    //     const url = BASE_URL + "/settings/store-hero-section";
    //     axios.post(url, formData, {
    //         headers: { Authorization: `Bearer ${getCookie()}` },
    //     }).then((res) => {
    //         if (res.data.status == 200) {
    //             toast.success(`${_t(t("updated successfully"))}`, {
    //                 position: "bottom-center",
    //                 autoClose: 10000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 className: "text-center toast-notification",
    //             });

    //         } else if (res.data.status == 422) {
    //             toast.error(`${_t(t("Try again"))}`, {
    //                 position: "bottom-center",
    //                 autoClose: 10000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 className: "text-center toast-notification",
    //             });

    //         }
    //     });
    // };

    return (
        <>
            <Helmet>
                <title>{_t(t("Allreservation"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReservationSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <Link to="/dashboard/reservation/add-bookiing" className="btn btn-primary my-4 ml-3">Add booking</Link>
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15 reservation-table">
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={reservation}
                                            columns={columns}
                                            pagination={paginationFactory({ sizePerPage: 5 })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Allreservation;
